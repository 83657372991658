<template>
  <v-tooltip bottom>
    <template v-slot:activator="{ on }">
      <v-chip color="transparent" v-on="on" label>{{ value | truncate(num, '...') }}</v-chip>
    </template>
    <span>{{ value }}</span>
  </v-tooltip>
</template>
<script>
export default {
  name: 'FormattedText',
  props: ['value', 'num'],
};
</script>
